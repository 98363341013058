"use client";

import { styled } from "@mui/material/styles";
import { ForwardedRef, forwardRef } from "react";
import { MarkRequired } from "ts-essentials";

import { ConsumerTripContentItemProductListLayout } from "@holibob-packages/graphql-types";
import { normalizePathPart } from "@holibob-packages/url";

import { useConsumerTripContentQuery, useConsumerTripQuery } from "../../apiHooks/graphql";
import { ProductsContainerSliderProps } from "../../product/ProductsContainerSlider";
import { HbmlConsumerTripProps, HeroImageConsumerTrip } from "../HbmlComponents";
import { HeroImages } from "../HeroImages";
import { ConsumerTripHeroContent, ConsumerTripHeroContentProps } from "./ConsumerTripHeroContent";
import { ConsumerTripStoriesAndShelves } from "./ConsumerTripStoriesAndShelves";

export type ConsumerTripContainerProps = {
    pagePath: string;
    heroImageAssetUrlList?: string[];
} & MarkRequired<HbmlConsumerTripProps, "ProductCardComponent"> &
    Omit<ProductsContainerSliderProps, "sliderItemWidth" | "children" | "onScroll">;

export const ConsumerTripContainer = forwardRef(function Component(
    props: ConsumerTripContainerProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { consumerTripId } = props;
    const { data: consumerTripData, loading } = useConsumerTripQuery({ variables: { consumerTripId } });
    const consumerTrip = consumerTripData?.consumerTrip;

    if (!consumerTrip && !loading)
        return <ConsumerTripHero {...props} hasMatchingDestinationPagePath={false} country={""} />;

    return (
        <div ref={ref}>{consumerTrip && <ConsumerTripContainerContent {...props} consumerTrip={consumerTrip} />}</div>
    );
});

export type ConsumerTripContainerContentProps = Omit<ConsumerTripContainerProps, "consumerTripId"> & {
    consumerTrip: HeroImageConsumerTrip;
};

function ConsumerTripContainerContent(props: ConsumerTripContainerContentProps) {
    const { consumerTrip, placeId, heroImageAssetUrlList, heroTitle, ProductCardComponent, productHrefFactory } = props;
    const { consumerTripId } = consumerTrip;
    const distributionChannelPagePath = normalizePathPart(props.pagePath);
    const hasMatchingDestinationPagePath = consumerTrip.destinationPagePath
        ? normalizePathPart(consumerTrip.destinationPagePath) === distributionChannelPagePath
        : false;

    const shouldQueryConsumerTrip = !!(placeId && consumerTripId) && hasMatchingDestinationPagePath;
    const { data } = useConsumerTripContentQuery({
        variables: { consumerTripId, placeId: placeId! },
        skip: !shouldQueryConsumerTrip,
    });
    const rules = data?.consumerTripContent?.itemList ?? [];

    const shouldRender = rules.length > 0 && !!placeId;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const sliderRules = rules.filter((rule) => rule.layout === ConsumerTripContentItemProductListLayout.Slider);

    return (
        <>
            <ConsumerTripHero
                consumerName={consumerTrip.consumer?.givenName}
                arrivalDate={consumerTrip.arrivalDate}
                departureDate={consumerTrip.departureDate}
                forecast={consumerTrip.weatherForecast}
                ProductCardComponent={ProductCardComponent}
                productHrefFactory={productHrefFactory}
                heroImageAssetUrlList={heroImageAssetUrlList}
                heroTitle={heroTitle}
                placeId={placeId}
                hasMatchingDestinationPagePath={hasMatchingDestinationPagePath}
                country={consumerTrip.destinationCountryName ?? ""}
            />
            {shouldRender ? (
                <ConsumerTripStoriesAndShelves
                    sliderRules={sliderRules}
                    consumerTripSystemTagIdList={data?.consumerTripContent?.systemTagIdList ?? []}
                    {...props}
                    placeId={placeId}
                />
            ) : null}
        </>
    );
}

type ConsumerTripHeroProps = {
    heroImageAssetUrlList?: string[];
    hasMatchingDestinationPagePath: boolean;
} & Omit<ConsumerTripHeroContentProps, "showPersonalizedData"> &
    Omit<ConsumerTripContainerProps, "consumerTripId" | "pagePath">;

const ConsumerTripHero = (props: ConsumerTripHeroProps) => {
    const { heroImageAssetUrlList, heroTitle, placeId, hasMatchingDestinationPagePath, country } = props;

    const withHeroImage = heroImageAssetUrlList?.length;

    if (!withHeroImage) return null;

    return (
        <HeroImagesStyled images={heroImageAssetUrlList} smallScreenHeight={400}>
            <ConsumerTripHeroContent
                {...props}
                title={heroTitle}
                showPersonalizedData={hasMatchingDestinationPagePath}
                country={country}
                placeId={placeId}
            />
        </HeroImagesStyled>
    );
};

const HeroImagesStyled = styled(HeroImages)(({ theme }) => ({
    "& [data-ref='heroCarouselOverlay']": {
        background: `linear-gradient(180deg, rgba(0,0,0,0.4) calc(100% - 130px), rgba(0,0,0,1))`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "bottom",
        "& .MuiContainer-root": {
            padding: theme.spacing(0, 0, 2, 0),
        },
    },
}));
