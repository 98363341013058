import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import { countryGreetingMapper } from "../../constants/countryGreetingMapper";
import { ConsumerTripWeatherForecastFragment } from "../../gql-request";
import { useNextTranslation } from "../../hooks";
import { ProductCardV2Props } from "../../product";
import { HeroImagesDescription, HeroImagesSubtitle, HeroImagesTitle } from "../HeroImages";
import { WeatherIcons } from "../WeatherIcons";
import { ConsumerTripSearchProducts } from "./ConsumerTripSearchProducts";

export type ConsumerTripHeroContentProps = {
    title?: string | null;
    country: string;
    consumerName?: string | null;
    arrivalDate?: string | null;
    departureDate?: string | null;
    forecast?: ConsumerTripWeatherForecastFragment[] | null;
    productHrefFactory?: any;
    placeId?: string | null;
    ProductCardComponent: React.ComponentType<ProductCardV2Props>;
    showPersonalizedData: boolean;
};
export const ConsumerTripHeroContent = ({
    title,
    country,
    consumerName,
    arrivalDate,
    departureDate,
    forecast,
    productHrefFactory,
    placeId,
    showPersonalizedData,
    ProductCardComponent,
}: ConsumerTripHeroContentProps) => {
    const [t] = useNextTranslation("product");
    const countryGreeting = countryGreetingMapper[country.toLowerCase()];
    const tripDateFormatted = formatTripDate({ arrivalDate, departureDate });
    return (
        <>
            <TitleWrapper>
                <HeroImagesTitle fontWeight="bold">{title}</HeroImagesTitle>
            </TitleWrapper>
            {showPersonalizedData && (
                <SubtitleWrapper>
                    {consumerName && (
                        <HeroImagesSubtitle lineClamp={1}>{`${
                            countryGreeting ?? "Hello"
                        }, ${consumerName}!`}</HeroImagesSubtitle>
                    )}
                    {tripDateFormatted && (
                        <HeroImagesDescription>
                            {`${t("consumerTripHeroContent.yourTrip")} | ${tripDateFormatted}`}
                        </HeroImagesDescription>
                    )}
                    <WeatherIcons forecast={forecast} />
                    <ConsumerTripSearchProducts
                        productHrefFactory={productHrefFactory}
                        placeId={placeId}
                        ProductCardComponent={ProductCardComponent}
                    />
                </SubtitleWrapper>
            )}
        </>
    );
};

type formatTripDate = {
    arrivalDate?: string | null;
    departureDate?: string | null;
};

function formatTripDate({ arrivalDate, departureDate }: formatTripDate) {
    const parsedArrivalDate = dayjs(arrivalDate);
    const parsedDepartureDate = departureDate ? dayjs(departureDate) : null;

    if (arrivalDate && parsedArrivalDate.isValid()) {
        if (parsedDepartureDate?.isValid()) {
            return parsedArrivalDate.format("D MMM") + " - " + parsedDepartureDate.format("D MMM YYYY");
        } else {
            return parsedArrivalDate.format("D MMM YYYY");
        }
    } else {
        return "";
    }
}

const TitleWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    width: "70%",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("md")]: {
        width: "100%",
    },
}));

const SubtitleWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    bottom: 0,
    padding: theme.spacing(0, 2, 2, 2),
}));
